import {useEffect, useState} from 'react';
import * as React from 'react';

import './SubscribeForm.scss';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const data = {
	title: 'Receive updates about the book',
	form: {
		name: 'Name',
		email: 'Email',
		submit: 'Submit',
	},
	postUrl: `https://themlsbook.us14.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`,
};

const CustomForm = ({ status, message, onValidated }) => {
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');

	const handleChangeEmail = (e) => setEmail(e.target.value);
	const handleChangeName = (e) => setName(e.target.value);

	const handleSubmit = (e) => {
		e.preventDefault();
		email &&
		name &&
		email.includes('@') &&
		onValidated({
			MERGE0: email,
			MERGE1: name,
		});
	}

	useEffect(() => {
		if (status === "success") clearFields();
	}, [status])

	const clearFields = () => {
		setName('');
		setEmail('');
	}

	return (
		<form onSubmit={(e) => handleSubmit(e)}>
			{status === "sending" && (
				<div className="subscribe__sending">
					Sending...
				</div>
			)}
			{status === "error" && (
				<div
					className="subscribe__error"
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			)}
			{status === "success" && (
				<div
					className="subscribe__success"
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			)}

			{!['success', 'sending'].includes(status) ? (
				<React.Fragment>
					<input
						onChange={handleChangeName}
						className="input-text d-block m-auto mb-2"
						type="text"
						placeholder={data.form.name}
						value={name}
						required
					/>
					<input
						onChange={handleChangeEmail}
						className="input-text d-block m-auto mb-3"
						type="email"
						placeholder={data.form.email}
						value={email}
						required
					/>

					<button
						type="submit"
						className="btn btn-primary"
					>
						{data.form.submit}
					</button>
				</React.Fragment>
			) : null}
		</form>
	);
};

const SubscribeForm = () => {
	return (
		<div className="subscribe-form text-center m-auto">
			<div className="subscribe-form__title">
				{ data.title }
			</div>

			<MailchimpSubscribe
				url={data.postUrl}
				render={({ subscribe, status, message }) => (
					<CustomForm
						status={status}
						message={message}
						onValidated={formData => subscribe(formData)}
					/>
				)}
			/>
		</div>
	)
}

export default SubscribeForm;
