import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './Button.scss';

const Button = ({ text = 'Read for free', href, to ="/read", className }) => {
  if (href) return (
    <a rel="noreferrer noopener" href={href} target="_blank">
      <button className={classnames(className, "try-it-btn", "btn", "btn-primary")}>
        { text }
      </button>
    </a>
  )

  return (
    <Link
      to={to}
      className={classnames(className, "try-it-btn", "btn", "btn-primary")}
    >
      { text }
    </Link>
  )
}

export default Button;
