import * as React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import SectionBookPresentation from '../components/SectionBookPresentation/SectionBookPresentation';
import SectionTableOfContents from '../components/SectionTableOfContents/SectionTableOfContents';
import SectionGit from '../components/SectionGit/SectionGit';
import SubscribeForm from '../components/SubscribeForm/SubscribeForm';
import TextWithImageLinkBlock from '../components/TextWithImageLinkBlock/TextWithImageLinkBlock';
import ImgBookBig from '../images/book-big.png';
import GuthubLinkImg from '../images/github-link.svg';
import JupyterLinkImg from '../images/jupyter-link.svg';

const data = {
  pageTitle: 'Machine Learning Simplified',
  links: [
    // {
    //   text: 'The Machine Learning Simplified is supplemented with a GitHub repository that contains .ipynb python implementations of concepts explained in the book',
    //   buttonText: 'Go to GitHub',
    //   link: 'https://google.com',
    //   image: GuthubLinkImg
    // },
    {
      text: 'Give it a try in Python! I\'ve developed a Jupyter Notebook that features Python implementations of the concepts, algorithms, statistical methods, and techniques covered in the book.',
      buttonText: 'Go to Jupyter',
      link: 'https://code.themlsbook.com',
      image: JupyterLinkImg
    }
  ]
}

const App = () => {
  return (
    <div>
      <Helmet>
        <title>{ data.pageTitle }</title>

        <meta property='og:title' content='Machine Learning Simplified: A gentle introduction to supervised learning'/>
        <meta property='og:image' content={ImgBookBig}/>
        <meta property='og:description' content='Machine Learning Simplified: A gentle introduction to supervised learning'/>
        <meta property='og:url' content='//www.---' />
      </Helmet>

      <Header onlyBackButton />

      <div className="content-container">
        <section className="section section--book-presentation">
          <SectionBookPresentation />
        </section>

        <section className="section section--table-of-contents">
          <SectionTableOfContents />
        </section>

        {/* <section className="section section--git">
          <SectionGit />
        </section> */}
        
        <section className="section section--subscribe-form">
          <SubscribeForm />
        </section>

        {/* 
        <section className="section section--git">
        <center><p>You can also support the author and get pdf through <a href="https://leanpub.com/themlsbook" target="_blank"><u><b>Leanpub</b></u></a>.</p></center>
        </section> */}

        <section className="section section--links">
          {data.links.map((el) => (
            <TextWithImageLinkBlock text={el.text} image={el.image} link={el.link} buttonText={el.buttonText}  />
          ))}
        </section>
      </div>

      <Footer hideBtn />
    </div>
  )
}

export default App;
