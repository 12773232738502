export const cards = [
    {
        text: "“A great book for beginners! Andrew Wolf breaks down the most complex processes into simple steps.”",
        author: "Passionately Plotted",
        source: "goodreads",
        highlighted: false,
        link: "https://www.goodreads.com/review/show/4688840958?book_show_action=true&from_review_page=1"
    },
    {
        text: "“This was very informative and so much can be learned from this. ”",
        author: "Sebrina Bancroft",
        source: "goodreads",
        highlighted: true,
        link: "https://www.goodreads.com/review/show/4688821998?book_show_action=true&from_review_page=1"
    },
    {
        text: "“I LOVED the book. Simple and intuitive examples explain complex concepts.”",
        author: "Helen",
        source: "goodreads",
        highlighted: false,
        link: "https://www.goodreads.com/review/show/4560821499?book_show_action=true&from_review_page=1"
    },
    {
        text: "“Andrew Wolf's book strips down one knowledge to a new and simplified way.”",
        author: "Isa Rodriguez",
        source: "goodreads",
        highlighted: false,
        link: "https://www.goodreads.com/review/show/4694088970?book_show_action=true&from_review_page=1"
    },
    {
        text: "“Focus on the intuition, didn’t cram up the book with python code. ”",
        author: "Eugene",
        source: "goodreads",
        highlighted: false,
        link: "https://www.goodreads.com/review/show/4553655569?book_show_action=true&from_review_page=1"
    },
    {
        text: "“Amazing book. so far the best resource I've ever read”",
        author: "Lorenzo",
        source: "goodreads",
        highlighted: true,
        link: "https://www.goodreads.com/review/show/4593641165?book_show_action=true&from_review_page=1"
    },
    {
        text: "“Everything is straightforward and separated into several sections.”",
        author: "Jailene",
        source: "goodreads",
        highlighted: true,
        link: "https://www.goodreads.com/review/show/4674318880?book_show_action=true&from_review_page=1"
    },
    {
        text: "“Great as an intro to machine learning, and as a refresher for those who already in the field.”",
        author: "Paul",
        source: "goodreads",
        highlighted: false,
        link: "https://www.goodreads.com/review/show/4700099918?book_show_action=true&from_review_page=1"
    },
    {
        text: "“Andrew Wolf has very well narrated all prospectives of machine learning.”",
        author: "Ciliya Joji",
        source: "goodreads",
        highlighted: false,
        link: "https://www.goodreads.com/review/show/4719694079?book_show_action=true&from_review_page=1"
    },
]