import React from 'react';
import Button from '../Button/Button';

import './TextWithImageLinkBlock.scss';

const TextWithImageLinkBlock = ({ text, image, link, buttonText }) => (
    <div className="text-with-image-link">
        <img className="text-with-image-link__image" src={image} alt="link" />
        <p className="text-with-image-link__text">{text}</p>
        <Button href={link} className="btn--text-with-image-link" text={buttonText} />
    </div>
)

export default TextWithImageLinkBlock;