import * as React from 'react';
import ReactStars from "react-rating-stars-component";

import ImgStar from '../../images/star.png';
import { prepareReviewsAmount } from '../../helpers';

import './StarsRating.scss';
import { Ribbon } from '../Ribbon/Ribbon';

const StarsRating = (props) => {
  const { rating, reviewsAmount, withRating, oldStyle, source, size = 20, ribbonProps, imagesSize } = { ...props }
  const { enabled: isRibbonEnabled, horizontal: isRibbonHorizontal } = ribbonProps || {};

  return (
    <div className="stars-rating d-inline-flex align-items-center">
      {!!oldStyle && [...Array(rating)].map((item, index) =>
        <div className="stars-rating__star" key={`star-` + index}>
          <img src={ImgStar} alt="Rating star" />
        </div>
      )}

      {!oldStyle && (
        <a target="_blank" rel="noreferrer" href={source.link}>
          <ReactStars
            count={5}
            size={size}
            value={rating}
            isHalf={true}
            edit={false}
            activeColor="#FFB520"
            classNames="stars-rating_new"
          />
        </a>
      )}

      {withRating && (
        <a target="_blank" rel="noreferrer" href={source.link} className="stars-rating__value">{rating}</a>
      )}

      { !!reviewsAmount && (
        <div className="stars-rating__reviews-amount">
          <a target="_blank" rel="noreferrer" href={source.link}>
            { `(${prepareReviewsAmount(reviewsAmount)})` }
          </a>
        </div>
      )}

      {source && (
        <div className='stars-rating-source__container'>
          {isRibbonEnabled && <Ribbon horizontal={isRibbonHorizontal} />}
          <div className="stars-rating__source">
            <a target="_blank" rel="noreferrer" href={source.link}>
              <img className="stars-rating__logo" src={imagesSize === 'm' ? source.logoM : source.logoS} alt="Seller logo" />
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default StarsRating;
