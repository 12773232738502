import AmazonLogo from './images/amazon-logo-m.svg';
import GoodreadsLogo from './images/goodreads-logo-m.svg';
import AmazonLogoS from './images/amazon-logo-s.svg';
import GoodreadsLogoS from './images/goodreads-logo-s.svg';

export const ratings = [
    {
        rating: 4.65,
        reviewsAmount: 175,
        source: {
            name: 'Amazon',
            logoM: AmazonLogo,
            logoS: AmazonLogoS,
            link: 'https://www.amazon.com/dp/B0B216KMM4/qid=1653304321',
        }
    }, 
    {
        rating: 4.95,
        reviewsAmount: 187,
        source: {
            name: 'Goodreads',
            logoM: GoodreadsLogo,
            logoS: GoodreadsLogoS,
            link: 'https://www.goodreads.com/book/show/60297347-machine-learning-simplified',
        }
    }
]

export const SourcesWithRibbon = ['Amazon'];