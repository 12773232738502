import * as React from 'react';
import StarsRating from '../StarsRating/StarsRating';
import ImgBookBig from '../../images/book-big.png';
import ImgBookBig2x from '../../images/book-big@2x.png';
import AvailableAmazon from '../../images/available-amazon.svg';
import AvailableGoodreads from '../../images/available-goodreads.svg';
import { ratings, SourcesWithRibbon } from '../../config';

import './SectionBookPresentation.scss';

const data = {
  author: 'Andrew Wolf',
  title: [
    'Machine Learning Simplified:',
    'A gentle introduction to supervised learning',
  ],
  bookDescription: [
    'The underlying goal of "Machine Learning Simplified" is to develop strong intuition for ML inside you. We would use simple intuitive examples to explain complex concepts, algorithms or methods, as well as democratize all mathematics behind machine learning.',
    'After reading this book, you would understand everything that comes into the scope of Supervised ML, and would be able to not only understand nitty-gritty details of mathematics behind the scene, but also explain to anyone how things work on a high level.'
  ],
  share: 'Share this story',
  links: [
    {
      url: 'https://www.amazon.com/dp/B0B216KMM4/qid=1653304321',
      image: AvailableAmazon,
      alt: 'Available on Amazon',
    },
    {
      url: 'https://leanpub.com/themlsbook',
      image: AvailableGoodreads,
      alt: 'Available on Leanpub',
    }
  ],
  socials: [
    {
      name: 'linkedin',
      url: 'https://www.linkedin.com/sharing/share-offsite/?url=https://themlsbook.com',
    },
    {
      name: 'twitter',
      url: 'https://twitter.com/intent/tweet?url=https://themlsbook.com',
    },
    {
      name: 'facebook',
      url: 'https://www.facebook.com/sharer/sharer.php?u=https://themlsbook.com',
    },
    // {
    //   name: 'instagram',
    //   url: 'https://google.com',
    // }
  ]
}

const SectionBookPresentation = () => {
  return (
    <div className="section-book-presentation">
      <div className="section-book-presentation__book">
        <img src={ImgBookBig} srcSet={`${ImgBookBig2x} 2x`} alt="Machine Learning Simplified" />
      </div>

      <div className="section-book-presentation__author">
        { data.author }
      </div>

      <div className="section-book-presentation__title">
        {data.title.map((txt, i) => (
          <React.Fragment key={txt}>
            { txt }
            { (i !== data.title.length - 1) && <br /> }
          </React.Fragment>
        ))}
      </div>

      <div className="section-book-presentation__rating">
        {ratings.length > 0 && ratings.map(({ rating, reviewsAmount, source }) => (
          <StarsRating 
            withRating={true} 
            source={source} 
            rating={rating} 
            reviewsAmount={reviewsAmount} 
            ribbonProps={{
              enabled: SourcesWithRibbon.includes(source.name),
              horizontal: true,
            }}
          />
        ))}
      </div>

      <div className="section-book-presentation__description">
        {data.bookDescription.map(txt => (
          <p key={txt}>{ txt }</p>
        ))}
      </div>

      {/* <div className="section-book-presentation__amazon">
        <AmazonLinks />
      </div> */}

      <div className="section-book-presentation__share">
        <span className="section-book-presentation__share-text">{ data.share }</span>
        <div className="share-icons">
          {data.socials.length && data.socials.map((social) => (
            <a href={social.url} target="_blank" rel="noreferrer noopener">
              <div className={`share-icons__item share-icons__item--${social.name}`} />
            </a>
          ))}
        </div>
      </div>

      <p className="section-book-presentation__footer">
        The book is free, but you can purchase{' '}
        <a href="https://www.amazon.com/dp/B0B216KMM4/qid=1653304321" className="section-book-presentation__footer_bold" target="_blank" rel="noreferrer noopener">EPUB</a> version through 
        Amazon or show your appreciation to the author and purchase{' '}
        <a href="https://leanpub.com/themlsbook" className="section-book-presentation__footer_bold" target="_blank" rel="noreferrer noopener">PDF</a> through Leanpub.
      </p>

      <div className="section-book-presentation__available-links">
        {data.links.length > 0 && data.links.map((link) => (
          <a href={link.url} alr={link.alt} target="_blank" rel="noreferrer noopener">
            <img src={link.image} alt={link.alt} />
          </a>
        ))}
      </div>
    </div>
  )
}

export default SectionBookPresentation;
