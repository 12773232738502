import * as React from 'react';
import Accordion from 'react-bootstrap/Accordion'

import './SectionFaq.scss';

const data = {
  title: 'FAQ',
  questions: [
    {
      title: 'Is the book free?',
      answer: 'Yes. You can read it <a href="https://themlsbook.com/read" target="_blank">here</a> in PDF format. In case you need EPUB format, you can buy it on <a href="https://www.amazon.com/dp/B0B216KMM4/qid=1653304321" target="_blank">Amazon</a> .',
    },
    {
      title: 'Is a hardcover edition available for purchase?',
      answer: 'Yes, you can purchase a hardcover edition. More information will be provided shortly.',
    },
    {
      title: 'I would like to thank the author. What could I do?',
      answer: 'If you\'d like to express your appreciation or financially contribute to what I do, you could leave a rating and buy the book on <a href="https://www.amazon.com/dp/B0B216KMM4/qid=1653304321" target="_blank">Amazon</a> (epub), or <a href="https://leanpub.com/themlsbook" target="_blank">Leanpub</a> (pdf).'
    },
    {
      title: 'Are there any prerequisites to reading this book?',
      answer: 'There are no strict prerequisites to reading this book. However, basic knowledge of mathematics (incl. derivatives and linear algebra) and inferential statistics (incl. linear/polynomial regressions and distributions) will undoubtedly help the readers to get the most out of this book.',
    },
    {
      title: 'What does this book cover?',
      answer: 'The initial seven chapters of the book lay the groundwork in supervised learning, offering thorough explanations of key concepts such as artificial intelligence, data science, machine learning, deep learning, and various fundamental algorithms like gradient descent and linear regression. It also discusses common challenges like overfitting, underfitting, and the trade-offs between bias and variance, alongside techniques like basis expansion and regularization. The following six chapters explore a range of advanced algorithms, including logistic regression, decision trees, ensemble models like boosting, bagging, and stacking, as well as support vector machines. These sections also delve into evaluation metrics such as accuracy, precision, recall, ROC AUC, logarithmic loss, and the F1 score.',
    }
  ],
};

const SectionFaq = () => {
  return (
    <div className="section-faq">
      <h2 className="section-faq__title">{ data.title }</h2>

      <Accordion defaultActiveKey="0" flush className="accordeon">
        {data.questions.map(question => (
          <Accordion.Item
            eventKey={question.title}
            key={ question.title }
            className="question"
          >
            <Accordion.Header>
              { question.title }
            </Accordion.Header>
            <Accordion.Body>
              <p dangerouslySetInnerHTML={{__html: question.answer}} />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  )
}

export default SectionFaq;
