import * as React from 'react';

import './SectionTableOfContents.scss';

const data = {
  chapters: [
    {
      title: 'FUNDAMENTALS OF SUPERVISED LEARNING',
      chapters: [
        {
          title: 'Introduction',
          url: 'https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:b7ad98b3-80ec-44cd-9d16-741f83ff2aaa#pageNum=12'
        },
        {
          title: 'Overview of Supervised Learning',
          url: 'https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:b7ad98b3-80ec-44cd-9d16-741f83ff2aaa#pageNum=22'
        },
        {
          title: 'Model Learning',
          url: 'https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:b7ad98b3-80ec-44cd-9d16-741f83ff2aaa#pageNum=36'
        },
        {
          title: 'Basis Expansion & Regularization',
          url: 'https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:b7ad98b3-80ec-44cd-9d16-741f83ff2aaa#pageNum=56'
        },
        {
          title: 'Model Selection',
          url: 'https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:b7ad98b3-80ec-44cd-9d16-741f83ff2aaa#pageNum=66'
        },
        {
          title: 'Feature Selection',
          url: 'https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:b7ad98b3-80ec-44cd-9d16-741f83ff2aaa#pageNum=76'
        },
        {
          title: 'Data Preparation',
          url: 'https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:b7ad98b3-80ec-44cd-9d16-741f83ff2aaa#pageNum=84'
        },
      ],
    },
    {
      title: 'ADVANCED SUPERVISED LEARNING ALGORITHMS',
      chapters: [
        // {
          //   title: 'Bayesian Models'
          //   // ,
          //   // url: 'http://www.google.com'
          // },
          {
            title: 'Tree-Based Models',
            url: 'https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:b5234056-bcc4-43f9-84e8-03b1d2b3e36a'
          },
          {
            title: 'Ensemble Models',
            url: 'https://acrobat.adobe.com/id/urn:aaid:sc:EU:c5a2857d-19a1-4759-ad16-c7794b159bc7'
          },
          {
            title: 'Logit Models',
            url: 'https://acrobat.adobe.com/id/urn:aaid:sc:EU:fe61517e-9ea0-493b-b57c-d68bf73d5ab1'
          },
          {
          title: 'Maximum Margin Models',
          url: 'https://acrobat.adobe.com/id/urn:aaid:sc:EU:f471470f-6a52-4ca7-a9c0-76416575cca8'
          },
          {
          title: 'Hyperparameter Tuning & Model Selection',
          url: 'https://acrobat.adobe.com/id/urn:aaid:sc:EU:42bf4bc1-55f1-4b12-a4ad-b83914b117e5'
          },
          {
          title: 'Evaluation Metrics',
          url: 'https://acrobat.adobe.com/id/urn:aaid:sc:EU:0abc0359-29ac-4366-bb68-b071fa23baaf'
        },
      ],
    },
  ],
};


const SectionTableOfContents = () => {
  const contentsTree = (list) =>
    <ol className="contents__list me-md-auto ms-md-auto">
      {list.chapters.map(node => (
        <li className="contents__list-item" key={node.title}>
          <span className="contents__list-item-title">
            {
              node.url
              ? (<a href={node.url} title="node.title" target="_blank" className="contents__list-item-link" rel="noreferrer">{ node.title }</a>)
              : node.title
            }
          </span>
          { node.chapters?.length && contentsTree(node)}
        </li>
      ))}
    </ol>;

  return (
    <div className="section-table-of-contents">
      { contentsTree(data) }
    </div>
  )
}

export default SectionTableOfContents;